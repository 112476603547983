import { MusicData } from "../types";

const music: MusicData = {
  title: "Fresh Prince of Bel Air",
  artist: "Will Smith (Le Boeuf Remix)",
  cover: "music/fresh.jpeg",
  audio: "music/fresh.mp3"
};

export default music;
